import React, { useEffect } from "react";
import axios from "axios";
import { DataTable } from "@flixbus/honeycomb-react";
import { getNextWeekday } from "./functions";

const UpcommingScans = (props) => {
  const [posts, setPosts] = React.useState([]);
  // const [errorMsg, setErrorMsg] = React.useState("");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const getScanList = async () => {
      const options = {
        method: "GET",
        url: `${API_ENDPOINT}/getScanList`,
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
      };
      const response = await axios(options);
      setPosts(response.data);
      if (response.data.length !== 0) {
        props.setNextWeekDay(
          getNextWeekday(response.data[response.data.length - 1].eta)
        );
      } else {
        props.setNextWeekDay(getNextWeekday());
      }
      //setErrorMsg("Error retrieving data");
    };
    if (props.accessToken !== "") {
      getScanList();
    }
  }, [props, API_ENDPOINT]);

  const headers = { cells: ["Queue", "Domain", "Started", "ETA"] };
  var scans_active = `${posts.length} scans`;
  if (posts.length === 1) {
    scans_active = `${posts.length} scan`;
  }

  return (
    <div>
      <h2 style={{ margin: "3px 0px" }}>Upcomming scans + reports</h2>
      <p style={{ margin: "3px 0px" }}>
        There are currently <b>{scans_active} + reports pending.</b>
      </p>
      <p style={{ margin: "3px 0px" }}>*1 scan/report per day</p>
      <DataTable
        headers={headers}
        rows={posts.map((post) => ({
          cells: [post.order, post.domain, post.started, post.eta],
        }))}
      />
      {/* {errorMsg ? <div>{errorMsg}</div> : null} */}
    </div>
  );
};

export default UpcommingScans;
