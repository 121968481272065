import React from "react";
import { Input, Button, Box } from "@flixbus/honeycomb-react";
import axios from "axios";

function DownloadReports(props) {
  const [domain, setDomain] = React.useState(props.domain);
  const [DomainError, setDomainError] = React.useState("");

  async function downloadReport() {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    if (domain === "") {
      setDomainError("Please enter a domain");
    } else {
      const options = {
        method: "GET",
        url: `${API_ENDPOINT}/getReportUrl`,
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
        params: {
          domain: domain,
        },
      };
      console.log(options);
      const response = await axios(options);
      console.log(response);
      if (response.data.includes("http")) {
        window.open(response.data);
      } else {
        props.onError({ type: "error", text: response.data });
      }
    }
  }

  return (
    <Box style={{ padding: 24 }}>
      <h2 style={{ margin: "3px 0px 18px" }}>Download finished reports</h2>
      <Input
        onChange={(e) => {
          setDomain(e.target.value);
          setDomainError("");
        }}
        name="domain"
        id="domain"
        label="Domain"
        value={domain}
        valid={DomainError ? false : undefined}
        infoError={DomainError ? DomainError : ""}
      />
      <div
        style={{
          margin: "24px 0px 0px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={downloadReport} type="submit" appearance="tertiary">
          Download report
        </Button>
      </div>
    </Box>
  );
}

export default DownloadReports;
