import React from "react";
import { Notification, NotificationContainer } from "@flixbus/honeycomb-react";

function Notifications({ notificationMessage }) {
  console.log(notificationMessage);
  return (
    <div>
      <NotificationContainer
        extraClasses="my-container-notifications"
        position="top-right"
      >
        <Notification
          toast
          appearance={notificationMessage.type}
          // dismissCallback={removeNotif}
          // closeProps={closeProps}
        >
          {notificationMessage.text}
        </Notification>
      </NotificationContainer>
    </div>
  );
}

export default Notifications;
