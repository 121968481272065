import React from "react";
import NotificationComponent from "./components/Notification";

import DownloadReports from "./components/DownloadReports";

import { useSearchParams } from "react-router-dom";
import { Button } from "@flixbus/honeycomb-react";

import { loginRequest } from "./authConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

function Reports() {
  const [searchParams] = useSearchParams();
  const [domain] = React.useState(searchParams.get("domain"));
  const [notificationMsg, setNotificationMsg] = React.useState({});
  const [accessToken, setAccessToken] = React.useState("");

  const { instance } = useMsal();

  const handleLogin = (instance) => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  const BACK_END_CLIENT_ID = process.env.REACT_APP_BACK_END_CLIENT_ID;
  const publicClientApp = new PublicClientApplication(msalConfig);
  const account = publicClientApp.getAllAccounts()[0];
  const accessTokenRequest = {
    scopes: [`${BACK_END_CLIENT_ID}/Default`],
    account: account,
  };

  publicClientApp
    .acquireTokenSilent(accessTokenRequest)
    .then(function (accessTokenResponse) {
      let accessToken = accessTokenResponse.accessToken;
      setAccessToken(accessToken);
    })
    .catch(function (error) {
      console.log(error);
    });

  return (
    <>
      <UnauthenticatedTemplate>
        <Button
          variant="primary"
          onClick={() => handleLogin(instance)}
          style={{ marginTop: 12 }}
        >
          Sign in using Azure AD single sign-on (SSO)
        </Button>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <div style={{ paddingTop: 12 }}>
          {notificationMsg.type && (
            <NotificationComponent notificationMessage={notificationMsg} />
          )}
          <DownloadReports
            accessToken={accessToken}
            domain={domain}
            onError={(msgObj) => {
              setNotificationMsg({ type: msgObj.type, text: msgObj.text });
            }}
          />
        </div>
      </AuthenticatedTemplate>
    </>
  );
}

export default Reports;
