export const getNextWeekday = (timestamp = Date.now(), days = 1) => {
  var now = new Date(timestamp);
  if (typeof timestamp == "string") {
    var parts = timestamp.split(".");
    now = new Date(`${parts[2]}.${parts[1]}.${parts[0]}`);
  }

  var dayOfTheWeek = now.getDay();
  var calendarDays = days;
  var deliveryDay = dayOfTheWeek + days;
  if (deliveryDay >= 6) {
    days -= 6 - dayOfTheWeek;
    calendarDays += 2;
    var deliveryWeeks = Math.floor(days / 5);
    calendarDays += deliveryWeeks * 2;
  }
  now.setTime(now.getTime() + calendarDays * 24 * 60 * 60 * 1000);
  return `${("0" + now.getDate()).slice(-2)}.${(
    "0" +
    (now.getMonth() + 1)
  ).slice(-2)}.${now.getFullYear()}`;
};
