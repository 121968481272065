import React, { useEffect } from "react";
import axios from "axios";
import { Box } from "@flixbus/honeycomb-react";
import {
  Input,
  Button,
  Autocomplete,
  Fieldset,
} from "@flixbus/honeycomb-react";

const filterAutocompleteMockData = (searchQuery, data) =>
  new Promise((resolve) => {
    setTimeout(() => {
      const res = data.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      resolve(res);
    }, 200);
  });

function RequestScan(props) {
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [domain, setDomain] = React.useState("");
  const [domainError, setDomainError] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [autocompleteData, setAutocompleteData] = React.useState([]);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  async function RequestReport() {
    // const domainValidation = autocompleteData.filter((item) =>
    //   item.title.toLowerCase().includes(domain.toLowerCase())
    // );
    if (domain === "") {
      setDomainError("Domain field is empty");
    }
    if (email === "") {
      setEmailError("Email field is empty");
    } else if (!email.includes("@flixbus.com")) {
      setEmailError("Email must reside within Flixbus organization");
    }
    if (emailError === "" && domainError === "") {
      var params = new URLSearchParams();
      params.append("email", email);
      params.append("endpoint", domain);

      const options = {
        method: "POST",
        url: `${API_ENDPOINT}/addToQueue`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${props.accessToken}`,
        },
        data: params,
      };

      try {
        const response = await axios(options);
        if (response.data === "OK") {
          setDomain("");
          setValue("");
          setEmail("");
          props.onSuccess({
            type: "success",
            text: "Added domain to scan list!",
          });
        } else {
          setDomainError(response.data);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  useEffect(() => {
    const getDomainList = async () => {
      const options = {
        method: "GET",
        url: `${API_ENDPOINT}/getDomainList`,
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
      };
      const response = await axios(options);
      setAutocompleteData(
        response.data.map((item) => ({
          ...item,
          contact_email: item.contact_email || "None",
        }))
      );
    };
    if (props.accessToken !== "") {
      getDomainList();
    }
  }, [props, API_ENDPOINT]);

  return (
    <Box>
      <div>
        <h2 style={{ margin: "3px 0px" }}>Request new scan + report</h2>
        <p style={{ margin: "3px 0px" }}>
          Scan your project for security vulnerabilities
        </p>
        <Fieldset>
          <Autocomplete
            id="autocomplete"
            placeholder="flixbus.com"
            label="Domain to scan:"
            valid={domainError ? false : undefined}
            infoError={domainError ? domainError : ""}
            options={data}
            value={value}
            loading={loading}
            onDebounce={(e) => {
              setLoading(true);

              filterAutocompleteMockData(e.target.value, autocompleteData).then(
                (options) => {
                  setData(options);
                  setLoading(false);
                }
              );
            }}
            onChange={(e) => {
              setDomain(e.target.value);
              setDomainError("");
              setLoading(true);
            }}
            onSelect={(item) => {
              setData(null);
              setDomain(item.title);
              setValue(item.title);
              if (item.contact_email !== "None") {
                setEmail(item.contact_email);
              } else {
                setEmail("");
              }
            }}
          />
        </Fieldset>

        <Input
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError("");
          }}
          name="email"
          id="email"
          value={email}
          label="E-mail for scan report"
          valid={emailError ? false : undefined}
          infoError={emailError ? emailError : ""}
        />
        <p>
          Report will be sent on: <b> {props.etaDate}.</b>
        </p>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={RequestReport} appearance="primary">
            Request report
          </Button>
        </div>
      </div>
    </Box>
  );
}

export default RequestScan;
