import React from "react";
import NotificationComponent from "./components/Notification";

import DownloadReports from "./components/DownloadReports";
import RequestScan from "./components/RequestScan";
import UpcommingScans from "./components/UpcommingScans";
import { GridCol, Grid, Button } from "@flixbus/honeycomb-react";

import { loginRequest } from "./authConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

function App() {
  const [nextWeekDay, setNextWeekDay] = React.useState("");
  const [notificationMsg, setNotificationMsg] = React.useState({});
  const [accessToken, setAccessToken] = React.useState("");

  const { instance } = useMsal();

  const handleLogin = (instance) => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  const BACK_END_CLIENT_ID = process.env.REACT_APP_BACK_END_CLIENT_ID;
  const publicClientApp = new PublicClientApplication(msalConfig);
  const account = publicClientApp.getAllAccounts()[0];
  const accessTokenRequest = {
    scopes: [`${BACK_END_CLIENT_ID}/Default`],
    account: account,
  };

  publicClientApp
    .acquireTokenSilent(accessTokenRequest)
    .then(function (accessTokenResponse) {
      let accessToken = accessTokenResponse.accessToken;
      setAccessToken(accessToken);
    })
    .catch(function (error) {
      console.log(error);
    });

  return (
    <>
      <UnauthenticatedTemplate>
        <Button
          variant="primary"
          onClick={() => handleLogin(instance)}
          style={{ marginTop: 12 }}
        >
          Sign in using Redirect
        </Button>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <div style={{ paddingTop: 12 }}>
          {notificationMsg.type && (
            <NotificationComponent notificationMessage={notificationMsg} />
          )}

          <Grid size={12} sm={12} md={7} align="top">
            <GridCol key="grid-btm-a" size={5}>
              <RequestScan
                accessToken={accessToken}
                etaDate={nextWeekDay}
                onSuccess={(msgObj) => {
                  setNotificationMsg({ type: msgObj.type, text: msgObj.text });
                }}
              />
              <DownloadReports
                accessToken={accessToken}
                onError={(msgObj) => {
                  setNotificationMsg({ type: msgObj.type, text: msgObj.text });
                }}
              />
            </GridCol>

            <GridCol pushSm={1} key="grid-btm-b" size={6}>
              <UpcommingScans
                accessToken={accessToken}
                nextWeekDay={nextWeekDay}
                setNextWeekDay={setNextWeekDay}
              />
            </GridCol>
          </Grid>
        </div>
      </AuthenticatedTemplate>
    </>
  );
}

export default App;
