import React from "react";
import { Header, HeaderUserWidget } from "@flixbus/honeycomb-react";
import { useMsal } from "@azure/msal-react";

const Headers = () => {
  const { accounts, inProgress } = useMsal();
  const brand = {
    alt: "Honeycomb",
    image: "logo.svg",
    href: "/",
  };

  return (
    <Header
      fixed={false}
      brand={brand}
      widget={
        inProgress === "none" &&
        accounts.length > 0 && (
          <HeaderUserWidget url="#" text={accounts[0].name} />
        )
      }
    />
  );
};

export default Headers;
