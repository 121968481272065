import React from "react";
import ReactDOM from "react-dom/client";
import { router } from "./router";
import Header from "./components/Header";
import { RouterProvider } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  Grid,
  GridCol,
  MainWrapper,
  PageContainer,
} from "@flixbus/honeycomb-react";
import "./index.css";

const msalInstance = new PublicClientApplication(msalConfig);
const { ThemeWrapper } = require("@flixbus/honeycomb-react");

ReactDOM.createRoot(document.getElementById("root")).render(
  <MsalProvider instance={msalInstance}>
    <ThemeWrapper>
      <MainWrapper>
        <Header />
        <PageContainer>
          <Grid>
            <GridCol key="grid-btm-a">
              <RouterProvider router={router} />
            </GridCol>
          </Grid>
        </PageContainer>
      </MainWrapper>
    </ThemeWrapper>
  </MsalProvider>
);
